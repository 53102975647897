import {
	UserAuthRead,
	UserRead,
	UserRoleTypeEnum,
	UserWithRoleIdRead,
} from "@apis/typescript-axios"

export type UserAuthType = Omit<UserAuthRead, "tokens">
export type UnAuthenticatedUserType = {
	user: undefined
	tokens: undefined
}
export { UserRoleTypeEnum }
export type { UserAuthRead, UserRead, UserWithRoleIdRead }

const dummyToken = {
	access: "access",
	refresh: "refresh",
}

export const dummyUser1: UserWithRoleIdRead = {
	id: "userId1",
	roleType: UserRoleTypeEnum.STUDENT,
	roleId: "roleId1",
	fullName: "田中 太郎",
	fullNameKana: "たなか たろう",
	lastName: "田中",
	firstName: "太郎",
	authProviderId: null,
	profileImageUrl: {
		size1200:
			"https://www.google.com/url?sa=i&url=https%3A%2F%2Fskywardplus.jal.co.jp%2Fplus_one%2Fcalendar%2Fdogday%2F&psig=AOvVaw1OpD2p1ryoejsqZWFNxpS2&ust=1639689198801000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCNi327Pc5vQCFQAAAAAdAAAAABAE",
		size400: "http://www.sozaiya.org/dog/cou0-up.gif",
		size100: "http://www.sozaiya.org/dog/cou0-up.gif",
	},
	canTestBetaFeatures: false,
	isTeacher: true,
	isStudent: false,
	isPatron: false,
	isDemoUser: false,
	isElementarySchoolStudent: false,
	shouldShowFurigana: false,
	studentGrade: 4,
	studentClassroomId: "aaa",
	teacherSchoolId: "bbb",
	canJoinToMultiSchoolsCourse: true,
	schoolTotalTerms: 2,
}
export const dummyAuthUser1 = {
	user: dummyUser1,
	tokens: dummyToken,
}

export const dummyUser2: UserWithRoleIdRead = {
	id: "userId2",
	roleType: UserRoleTypeEnum.STUDENT,
	roleId: "roleId2",
	fullName: "鈴木 二郎",
	fullNameKana: "すずき じろう",
	lastName: "鈴木",
	firstName: "二郎",
	authProviderId: null,
	profileImageUrl: {
		size1200: null,
		size400: "http://www.sozaiya.org/dog/cou0-up.gif",
		size100:
			"https://pbs.twimg.com/profile_images/1188747996843761665/8CiUdKZWsize100x100.jpg",
	},
	canTestBetaFeatures: false,
	isTeacher: true,
	isStudent: false,
	isPatron: false,
	isDemoUser: false,
	isElementarySchoolStudent: false,
	shouldShowFurigana: false,
	studentGrade: 4,
	studentClassroomId: "aaa",
	teacherSchoolId: "bbb",
	canJoinToMultiSchoolsCourse: true,
	schoolTotalTerms: 2,
}

export const dummyAuthUser2: UserAuthType = {
	user: dummyUser2,
	// tokens: dummyToken
}

export const dummyUser3: UserWithRoleIdRead = {
	id: "userId3",
	roleType: UserRoleTypeEnum.STUDENT,
	roleId: "roleId1",
	fullName: "田中 太郎",
	fullNameKana: "たなか たろう",
	lastName: "田中",
	firstName: "太郎",
	authProviderId: null,
	profileImageUrl: {
		size1200: null,
		size400: "http://www.sozaiya.org/dog/cou0-up.gif",
		size100: null,
	},
	canTestBetaFeatures: false,
	isTeacher: false,
	isStudent: true,
	isPatron: false,
	isDemoUser: false,
	isElementarySchoolStudent: false,
	shouldShowFurigana: false,
	studentGrade: 4,
	studentClassroomId: "aaa",
	teacherSchoolId: "bbb",
	canJoinToMultiSchoolsCourse: true,
	schoolTotalTerms: 2,
}

export const dummyUser4: UserWithRoleIdRead = {
	id: "dbde1d8c-cef7-4a27-ae4e-67ea66a1eae0",
	roleType: UserRoleTypeEnum.TEACHER,
	roleId: "roleId4",
	fullName: "山田 花子",
	fullNameKana: "やまだ はなこ",
	lastName: "山田",
	firstName: "花子",
	authProviderId: null,
	profileImageUrl: {
		size1200: null,
		size400: "http://www.sozaiya.org/dog/cou0-up.gif",
		size100: null,
	},
	canTestBetaFeatures: false,
	isTeacher: true,
	isStudent: false,
	isPatron: false,
	isDemoUser: false,
	isElementarySchoolStudent: false,
	shouldShowFurigana: false,
	studentGrade: 4,
	studentClassroomId: "aaa",
	teacherSchoolId: "bbb",
	canJoinToMultiSchoolsCourse: true,
	schoolTotalTerms: 2,
}

export const dummyUser5: UserWithRoleIdRead = {
	id: "userId5",
	roleType: UserRoleTypeEnum.TEACHER,
	roleId: "roleId5",
	fullName: "佐藤花子",
	fullNameKana: "さとうはなこ",
	lastName: "佐藤",
	firstName: "花子",
	authProviderId: null,
	profileImageUrl: {
		size1200: null,
		size400: "http://www.sozaiya.org/dog/cou0-up.gif",
		size100: null,
	},
	canTestBetaFeatures: false,
	isTeacher: true,
	isStudent: false,
	isPatron: false,
	isDemoUser: false,
	isElementarySchoolStudent: false,
	shouldShowFurigana: false,
	studentGrade: 4,
	studentClassroomId: "aaa",
	teacherSchoolId: "bbb",
	canJoinToMultiSchoolsCourse: true,
	schoolTotalTerms: 2,
}

export const dummyUser6: UserWithRoleIdRead = {
	id: "userId6",
	roleType: UserRoleTypeEnum.PATRON,
	roleId: "roleId6",
	fullName: "保護者花子",
	fullNameKana: "ほごしゃはなこ",
	lastName: "保護者",
	firstName: "花子",
	authProviderId: null,
	profileImageUrl: {
		size1200: null,
		size400: "http://www.sozaiya.org/dog/cou0-up.gif",
		size100: null,
	},
	canTestBetaFeatures: false,
	isTeacher: false,
	isStudent: false,
	isPatron: true,
	isDemoUser: false,
	isElementarySchoolStudent: false,
	shouldShowFurigana: false,
	studentGrade: 4,
	studentClassroomId: "aaa",
	teacherSchoolId: "bbb",
	canJoinToMultiSchoolsCourse: true,
	schoolTotalTerms: 2,
}
