import {
	Button,
	Center,
	Checkbox,
	Collapse,
	HStack,
	IconButton,
	VStack,
} from "@chakra-ui/react"
import { CCHStack } from "@components/CCHStack"
import { PrivacyPolicyContent } from "@components/PrivacyPolicyContent"
import { TermsContent } from "@components/TermsContent"
import { TextWithFurigana } from "@components/TextWithFurigana"
import { useModal } from "@hooks/useModal"
import { CloseIcon } from "@icons/CloseIcon"
import { DownArrowIcon } from "@icons/DownArrowIcon"
import { colors } from "@styles/colors"
import { UserRoleTypeEnum } from "@type/models/User"
import { useMemo, useState } from "react"

type Params = {
	roleType: UserRoleTypeEnum
}

export const useAgreementForRegistration = ({ roleType }: Params) => {
	const [hasUserAgreedToTerms, setHasUserAgreedToTerms] = useState(false)
	const [hasUserAgreedToPP, setHasUserAgreedToPP] = useState(false)
	const {
		ModalWrapper: TermsModalWrapper,
		openModal: openTermsModal,
		closeModal: closeTermsModal,
	} = useModal({})
	const {
		ModalWrapper: PPModalWrapper,
		openModal: openPPModal,
		closeModal: closePPModal,
	} = useModal({})

	const TermsAndPPButtons = useMemo(
		() => (
			<>
				<VStack alignItems="flex-start">
					<CCHStack alignItems="center" flexWrap="wrap" gap={2}>
						<Checkbox
							onChange={(e) => setHasUserAgreedToTerms(e.target.checked)}
							value="agree"
							aria-label="利用規約に同意します"
							size="lg"
							pr={1}
						/>
						<Button onClick={openTermsModal} ml={2} variant="link">
							<TextWithFurigana
								furigana="りようきやく"
								align="left"
								fontWeight="bold"
							>
								利用規約
							</TextWithFurigana>
						</Button>
						<TextWithFurigana furigana="    どうい" align="left">
							に同意します
						</TextWithFurigana>
					</CCHStack>
					<CCHStack alignItems="center" flexWrap="wrap" gap={2}>
						<Checkbox
							onChange={(e) => setHasUserAgreedToPP(e.target.checked)}
							value="agree"
							aria-label="プライバシーポリシーに同意します"
							size="lg"
							pr={1}
						/>
						<Button onClick={openPPModal} variant="link">
							<TextWithFurigana
								furigana="ぷらいばしーぽりしー"
								align="left"
								fontWeight="bold"
							>
								プライバシーポリシー
							</TextWithFurigana>
						</Button>
						<TextWithFurigana furigana="    どうい" align="left">
							に同意します
						</TextWithFurigana>
					</CCHStack>
				</VStack>
				<Collapse
					in={
						roleType === UserRoleTypeEnum.STUDENT &&
						!hasUserAgreedToTerms &&
						!hasUserAgreedToPP
					}
					animateOpacity
				>
					<HStack mt={1}>
						<DownArrowIcon
							size={22}
							style={{ marginTop: 4, transform: "rotate(180deg)" }}
						/>
						<TextWithFurigana
							furigana="ここをたっぷしてね"
							align="left"
							fontWeight={"bold"}
						>
							ここをタップしてね
						</TextWithFurigana>
					</HStack>
				</Collapse>
			</>
		),
		[
			hasUserAgreedToTerms,
			hasUserAgreedToPP,
			openTermsModal,
			openPPModal,
			roleType,
		],
	)

	const TermsModal = useMemo(
		() => (
			<TermsModalWrapper bg="rgba(0,0,0,0)">
				<VStack bgColor="#fff" h="90vh" overflow={"auto"} position="relative">
					<IconButton
						aria-label="close Modal"
						variant="solid"
						bg={"#fff"}
						_hover={{ bg: colors.blackAlpha[100] }}
						rounded="full"
						position="absolute"
						top={4}
						right={4}
						onClick={closeTermsModal}
						icon={
							<CloseIcon
								style={{
									color: colors.blackAlpha[700],
									fontSize: 24,
								}}
							/>
						}
					/>
					<TermsContent />
					<Center
						pb={10}
						borderTopColor={colors.border.gray}
						borderTopWidth={1}
					>
						<Button variant={"link"} onClick={closeTermsModal}>
							<TextWithFurigana
								color={colors.ui.primary[500]}
								furigana="とじる"
								fontWeight="bold"
							>
								閉じる
							</TextWithFurigana>
						</Button>
					</Center>
				</VStack>
			</TermsModalWrapper>
		),
		[TermsModalWrapper, closeTermsModal],
	)
	const PPModal = useMemo(
		() => (
			<PPModalWrapper bg="rgba(0,0,0,0)">
				<VStack bgColor="#fff" h="90vh" overflow={"auto"} position="relative">
					<IconButton
						aria-label="close Modal"
						variant="solid"
						bg={"#fff"}
						_hover={{ bg: colors.blackAlpha[100] }}
						rounded="full"
						position="absolute"
						top={4}
						right={4}
						onClick={closePPModal}
						icon={
							<CloseIcon
								style={{
									color: colors.blackAlpha[700],
									fontSize: 24,
								}}
							/>
						}
					/>
					<PrivacyPolicyContent />
					<Center
						pb={10}
						borderTopColor={colors.border.gray}
						borderTopWidth={1}
					>
						<Button variant={"link"} onClick={closePPModal}>
							<TextWithFurigana
								color={colors.ui.primary[500]}
								furigana="とじる"
								fontWeight="bold"
							>
								閉じる
							</TextWithFurigana>
						</Button>
					</Center>
				</VStack>
			</PPModalWrapper>
		),
		[PPModalWrapper, closePPModal],
	)
	const hasUserAgreed = hasUserAgreedToTerms && hasUserAgreedToPP

	return {
		hasUserAgreedToTerms,
		hasUserAgreedToPP,
		hasUserAgreed,
		TermsAndPPButtons,
		TermsModal,
		PPModal,
	}
}
