import { HStack, StackProps } from "@chakra-ui/react"
import React, { FC } from "react"

export type CCHStackProps = StackProps

export const CCHStack: FC<CCHStackProps> = (props) => {
	return (
		<HStack
			spacing={0}
			justifyContent="flex-start"
			alignItems="flex-start"
			{...props}
		/>
	)
}
