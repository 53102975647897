import { AccountsApi } from "@apis/typescript-axios"
import { ModalBody } from "@chakra-ui/react"
import { AgreeContent } from "@features/termAgreement/AgreeContent"
import { useAuth } from "@hooks/useAuth"
import { useCCToast } from "@hooks/useCCToast"
import { useErrorToast } from "@hooks/useErrorToast"
import { useModal } from "@hooks/useModal"
import { useUser } from "@hooks/useUser"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

const getApi = () => new AccountsApi()

type Props = {}

export const ForceTermAgreeModal: FC<Props> = ({}) => {
	const { addToast } = useCCToast()
	const { addErrorToast } = useErrorToast()
	const [isSetting, setIsSetting] = useState(false)
	const { ModalWrapper, isOpen, openModal, closeModal } = useModal({
		modalProps: {
			closeOnOverlayClick: false,
			closeOnEsc: false,
		},
	})

	const user = useUser()
	const { setUserInfo } = useAuth()

	const agreeToTerm = useCallback(
		async (termVersion: string) => {
			try {
				setIsSetting(true)
				await getApi().agreeToTerm({ termVersion })
				const { data } = await getApi().checkAuth()
				await setUserInfo(data)
				closeModal()
			} catch (error) {
				addErrorToast({
					error,
					baseText: "失敗しました",
					addServerErrorMessages: true,
				})
				console.error(error)
			} finally {
				setIsSetting(false)
			}
		},
		[addToast, setUserInfo, closeModal, addErrorToast],
	)

	useEffect(() => {
		if (!user || user?.hasAgreedLatestTerm) return
		openModal()
	}, [user])

	const Modal = useMemo(
		() => (
			<ModalWrapper
				hideCloseButton
				maxW={{ base: "100dvw", md: "600px" }}
				maxH="calc(100dvh - 60px)"
				style={{ marginTop: 40, marginBottom: 20 }}
			>
				<ModalBody>
					<AgreeContent agreeToTerm={agreeToTerm} isSetting={isSetting} />
				</ModalBody>
			</ModalWrapper>
		),
		[ModalWrapper, agreeToTerm, isSetting],
	)

	return isOpen ? Modal : null
}
