import React, { FC, useState, useEffect } from "react"
import {
	Center,
	Text,
	OrderedList,
	UnorderedList,
	ListItem,
	VStack,
	List
} from "@chakra-ui/react"

export const PrivacyPolicyContent: FC = () => {
	return (
		<VStack p={{ base: 6, md: 100 }} py={100}>
			<Center
				flexDirection={"column"}
				alignSelf={"center"}
				w={{ base: "100%", md: 600 }}
			>
				<Text fontSize="xl" fontWeight={"bold"}>
					プライバシーポリシー
				</Text>
				<Text style={{ marginTop: 20 }}>
					このプライバシーポリシー（以下、「本ポリシー」といいます。）は、株式会社Mikulak（以下「運営者」といいます。）が提供するサービス「ClassCloud」（以下「本サービス」といいます。）が取得する個人情報を含む利用者情報の取扱いを定めるものです。本サービスは、本ポリシーの内容をご確認、ご同意の上、ご利用ください。
				</Text>
				<OrderedList spacing={4} style={{ marginTop: 40 }}>
					{/* 1 */}
					<ListItem>
						収集する利用者情報及び収集方法
						本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき運営者が収集するものを意味するものとします。
						本サービスにおいて運営者が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
						<OrderedList>
							<ListItem>
								ユーザーからご提供いただく情報
								本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
								<UnorderedList>
									<ListItem>
										氏名、生年月日、性別、職業等プロフィールに関する情報
									</ListItem>
									<ListItem>
										メールアドレス、電話番号、住所等連絡先に関する情報
									</ListItem>
									<ListItem>
										クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報
									</ListItem>
									<ListItem>ユーザーの肖像を含む静止画情報</ListItem>
									<ListItem>
										入力フォームその他運営者が定める方法を通じてユーザーが入力または送信する情報
									</ListItem>
								</UnorderedList>
							</ListItem>
							<ListItem>
								ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
								ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
								<UnorderedList>
									<ListItem>当該外部サービスでユーザーが利用するID</ListItem>
									<ListItem>
										その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
									</ListItem>
								</UnorderedList>
							</ListItem>
							<ListItem>
								ユーザーが本サービスを利用するにあたって、運営者が収集する情報
								運営者は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
								<UnorderedList>
									<ListItem>リファラ</ListItem>
									<ListItem>IPアドレス</ListItem>
									<ListItem>サーバーアクセスログに関する情報</ListItem>
									<ListItem>Cookie、ADID、IDFAその他の識別子</ListItem>
								</UnorderedList>
							</ListItem>
							<ListItem>
								ユーザーが本サービスを利用するにあたって、運営者がユーザーの個別同意に基づいて収集する情報
								運営者は、ユーザーが3-1に定める方法により個別に同意した場合、運営者は以下の情報を利用中の端末から収集します。
								<UnorderedList>
									<ListItem>位置情報</ListItem>
								</UnorderedList>
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 2 */}
					<ListItem>
						利用目的
						本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
						<OrderedList>
							<ListItem>
								本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
							</ListItem>
							<ListItem>ユーザーのトラフィック測定及び行動測定のため</ListItem>
							<ListItem>広告の配信、表示及び効果測定のため</ListItem>
							<ListItem>
								本サービスに関するご案内、お問い合わせ等への対応のため
							</ListItem>
							<ListItem>
								本サービスに関する運営者の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
							</ListItem>
							<ListItem>
								本サービスに関する規約等の変更などを通知するため
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 3 */}
					<ListItem>
						通知・公表または同意取得の方法、利用中止要請の方法
						<OrderedList
							style={{ counterReset: "section", listStyleType: "none" }}
						>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"3-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。
								<UnorderedList>
									<ListItem>位置情報</ListItem>
								</UnorderedList>
							</ListItem>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"3-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、運営者は速やかに、運営者の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、運営者所定の方法により本サービスを退会した場合に限り、運営者はその収集又は利用を停止します。
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 4 */}
					<ListItem>
						外部送信、第三者提供、情報収集モジュールの有無
						<OrderedList
							style={{ counterReset: "section", listStyleType: "none" }}
						>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"4-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								本サービスでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。
								<OrderedList>
									<ListItem>提携先</ListItem>
									<ListItem>上記提携先のプライバシーポリシーのURL</ListItem>
									<ListItem>上記提携先のオプトアウト（無効化）URL</ListItem>
								</OrderedList>
							</ListItem>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"4-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。
								<OrderedList>
									<ListItem>情報収集モジュールの名称</ListItem>
									<ListItem>情報収集モジュールの提供者</ListItem>
									<ListItem>提供される利用者情報の項目</ListItem>
									<ListItem>提供の手段・方法</ListItem>
									<ListItem>上記提供者における利用目的</ListItem>
									<ListItem>上記提供者における第三者提供の有無</ListItem>
									<ListItem>上記提供者のプライバシーポリシーのURL</ListItem>
								</OrderedList>
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 5 */}
					<ListItem>
						第三者提供
						運営者は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
						<OrderedList>
							<ListItem>
								運営者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
							</ListItem>
							<ListItem>
								合併その他の事由による事業の承継に伴って個人情報が提供される場合
							</ListItem>
							<ListItem>
								第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
							</ListItem>
							<ListItem>
								国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
							</ListItem>
							<ListItem>
								その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 6 */}
					<ListItem>
						共同利用 運営者は、以下のとおりユーザーの個人情報を共同利用します。
						<OrderedList>
							<ListItem> 共同して利用される個人情報の項目</ListItem>
							<ListItem> 共同して利用する者の範囲</ListItem>
							<ListItem> 共同して利用する者の利用目的</ListItem>
							<ListItem>
								{" "}
								個人情報の管理について責任を有する者の氏名または名称
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 7 */}
					<ListItem>
						個人情報の開示
						運営者は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、運営者が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
					</ListItem>
					{/* 8 */}
					<ListItem>
						個人情報の訂正及び利用停止等
						<OrderedList
							style={{ counterReset: "section", listStyleType: "none" }}
						>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"8-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								運営者は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
							</ListItem>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"8-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								運営者は、ユーザーから、ユーザーの個人情報について消去を求められた場合、運営者が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
							</ListItem>
							<ListItem
								_before={{
									counterIncrement: "section",
									content: `"8-" counter(section, decimal) ". "`,
									marginLeft: -4
								}}
							>
								個人情報保護法その他の法令により、運営者が訂正等または利用停止等の義務を負わない場合は、8-1および8-2の規定は適用されません。
							</ListItem>
						</OrderedList>
					</ListItem>
					{/* 9 */}
					<ListItem>
						お問い合わせ窓口
						ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
						<br />
						<List>
							<ListItem>
								住所：〒151-0031 東京都渋谷区桜丘町23番17号シティコート桜丘408
							</ListItem>
							<ListItem>株式会社Mikulak</ListItem>
							<ListItem>個人情報取扱責任者：原田眞</ListItem>
							<ListItem>連絡先：support@class-cloud.jp</ListItem>
						</List>
					</ListItem>
					{/* 10 */}
					<ListItem>
						プライバシーポリシーの変更手続
						運営者は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、運営者所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、運営者は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を運営者のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
					</ListItem>
				</OrderedList>
				<VStack alignSelf={"flex-end"} alignItems="flex-end" spacing={1} mt={3}>
					<Text>以上</Text>
					<Text>2022年2月20日制定</Text>
				</VStack>
			</Center>
		</VStack>
	)
}
