import {
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	ModalProps,
} from "@chakra-ui/react"
import { Modal } from "@components/Modal"
import { colors } from "@styles/colors"
import { ComponentProps, useCallback, useState } from "react"

type Params = {
	modalProps?: Partial<ModalProps>
}

export const useModal = ({ modalProps }: Params) => {
	const [isOpen, setIsOpen] = useState(false)
	const closeModal = useCallback(() => setIsOpen(false), [])
	const openModal = useCallback(() => setIsOpen(true), [])

	const ModalWrapper = useCallback(
		({
			children,
			modalOverlayProps,
			modalCloseButtonProps,
			hideCloseButton = false,
			...modalContentProps
		}: {
			children: React.ReactNode
			hideCloseButton?: boolean
			modalOverlayProps?: ComponentProps<typeof ModalOverlay>
			modalCloseButtonProps?: ComponentProps<typeof ModalCloseButton>
		} & ComponentProps<typeof ModalContent>) => {
			return (
				<Modal
					closeModal={closeModal}
					isOpen={isOpen}
					isCentered
					{...modalProps}
				>
					<ModalOverlay {...modalOverlayProps} />
					<ModalContent
						maxW="100vw"
						maxH="100dvh"
						alignItems={"center"}
						justifyContent="center"
						m={0}
						{...modalContentProps}
					>
						{!hideCloseButton && (
							<ModalCloseButton
								rounded="full"
								bg={colors.blackAlpha[700]}
								color="#fff"
								zIndex={2}
								{...modalCloseButtonProps}
							/>
						)}
						{children}
					</ModalContent>
				</Modal>
			)
		},
		[isOpen, closeModal], //modalPropsは更新しない前提
	)

	return {
		isOpen,
		closeModal,
		openModal,
		ModalWrapper,
	}
}
