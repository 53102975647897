import { Button, VStack } from "@chakra-ui/react"
import { CURRENT_TERMS_VERSION } from "@components/TermsContent"
import { useAgreementForRegistration } from "@features/termAgreement/useAgreementForRegistration"
import { useUser } from "@hooks/useUser"
import { UserRoleTypeEnum } from "@type/models/User"
import { FC } from "react"

type Props = {
	agreeToTerm: (version: string) => void
	isSetting: boolean
}

export const AgreeContent: FC<Props> = ({ agreeToTerm, isSetting }) => {
	const user = useUser()
	const { hasUserAgreed, TermsAndPPButtons, TermsModal, PPModal } =
		useAgreementForRegistration({
			roleType: user?.roleType || UserRoleTypeEnum.STUDENT,
		})
	return (
		<VStack p={10}>
			{TermsAndPPButtons}
			{TermsModal}
			{PPModal}
			{hasUserAgreed && (
				<Button
					mt={6}
					isLoading={isSetting}
					onClick={() => agreeToTerm(CURRENT_TERMS_VERSION)}
					w="200px"
				>
					OK
				</Button>
			)}
		</VStack>
	)
}
